import React from 'react';
import logo from './wolf.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className="footer">
        <a href="https://www.flaticon.com/de/kostenloses-icon/wolf_8543559">Icon from Marz Gallery</a>
      </div>
    </div>
  );
}

export default App;
